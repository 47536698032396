import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "react-phone-input-2/lib/style.css";
import App from "./App";

import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { setContext } from "@apollo/client/link/context";
import { split } from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";

import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";

//switch to development in dev, switch to production locally the text string
const isProduction = process.env.NODE_ENV === "development";
const httpLink = createHttpLink({
  uri: isProduction
    ? process.env.REACT_APP_GRAPHQL_SERVER_PROD
    : process.env.REACT_APP_GRAPHQL_SERVER,
});

const wsLink = new WebSocketLink({
  uri: `wss://${
    isProduction
      ? process.env.REACT_APP_GRAPHQL_WS_PROD
      : process.env.REACT_APP_GRAPHQL_WS
  }`,
  options: {
    reconnect: true,
  },
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </ApolloProvider>
  </BrowserRouter>
);
